import React from "react"
// import Layout from "../components/utils/layout"
// import SEO from "../components/utils/seo"
// import TeacherProfile from "../components/onboarding/TeacherProfile"
 
const ProfileTeacher = () => (
  <></>
  // <Layout>
  //   <SEO title="My Profile" />
  //   <TeacherProfile />
  // </Layout>
)

export default ProfileTeacher
